import axiosIns from '@/libs/axios'

export default class CustomerWalletApis {
  getDepositHistoryInfo = async () => await axiosIns.get('deposit')

  getCryptoWalletsInfo = async () => await axiosIns.get('customer-wallet')

  getWithdrawHistoryInfo = async () => await axiosIns.get('withdraw')

  storeWithdraw = async data => await axiosIns.post('withdrawal-ticket-bank-module/store', data)

  getWalletAddress = async ({ currency, network }) => await axiosIns.post('crypto/wallet/address', { currency, network })

  getUserWallets =async data => await axiosIns.get('crypto/wallet', { params: data })

  getCurrencies = async () => await axiosIns.get('crypto/wallet/currency')

  getOmsCoins = async () => await axiosIns.get('omsmodule/coins')

  withdrawalCryptoWallet = async data => await axiosIns.post('crypto/wallet/withdrawal', data);

  withdrawalSendCode = async data => await axiosIns.post('crypto/wallet/otp/send', data);

  getCoin = async tokenSymbol => await axiosIns.get(`crypto/wallet/currency/${tokenSymbol}/show`);

  getBalance = async () => await axiosIns.get('omsmodule/account');

  lastDeposits = async data => await axiosIns.get(`crypto/wallet/transaction/deposit?page=${data.current_page}&per_page=${data.per_page}`)

  withdrawTransactions = async data => await axiosIns.get(`crypto/wallet/transaction/withdraw?page=${data.current_page}&per_page=${data.per_page}`)
}
